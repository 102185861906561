import React from 'react';
import { string, array } from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../components/';

import css from './SectionAsFeatured.css';

const SectionAsFeatured = props => {
  const { 
    rootClassName, 
    className, 
    title,
    logos,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const logoList = logos ? logos.map((logo, index) => {
    return (
      <img
        src={logo.url}
        width={logo.width}
        height={logo.height}
        alt={logo.alt}
      />
    )}
    )
    : null;
  
  return (
    <div className={classes}>
      <h2 className={css.title}> { title} </h2>
      { logoList && 
        <>{logoList}</>
      }
    </div>
  );
};

SectionAsFeatured.defaultProps = { 
  rootClassName: null, 
  className: null,
  title: null,
  logos: null
};

SectionAsFeatured.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  logos: array
};

export default SectionAsFeatured;
