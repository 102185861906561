import config from '../config';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import GhostContentAPI from '@tryghost/content-api';

// ================ Action types ================ //

const GHOST_CONNECT_TO_SDK = 'app/ghost/GHOST_CONNECT_TO_CMS';
const GHOST_FETCH_PAGES_SUCCESS = 'app/ghost/GHOST_FETCH_PAGES_SUCCESS';
const GHOST_FETCH_PAGES_ERROR = 'app/ghost/GHOST_FETCH_PAGES_ERROR';
const SELECT_PAGE_BY_SLUG_SUCCESS = 'app/ghost/SELECT_PAGE_BY_SLUG_SUCCESS';
const SELECT_PAGE_BY_SLUG_ERROR = 'app/ghost/SELECT_PAGE_BY_SLUG_ERROR';

// ================ Reducer ================ //

const initialState = {
  ghostConnection: false,
  ghostPages: [],
  ghostActivePage: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GHOST_CONNECT_TO_SDK:
      return { ...state, ghostConnection: payload };

    case GHOST_FETCH_PAGES_SUCCESS:
      return { ...state, ghostPages: payload };

    case GHOST_FETCH_PAGES_ERROR:
      return state;

    case SELECT_PAGE_BY_SLUG_SUCCESS:
      return { ...state, ghostActivePage: payload };

    case SELECT_PAGE_BY_SLUG_ERROR:
      return { ...state, ghostActivePage: {} };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const ghostConnectToSdk = connection => ({ type: GHOST_CONNECT_TO_SDK, payload: connection });
const ghostFetchPagesSuccess = pages => ({ type: GHOST_FETCH_PAGES_SUCCESS, payload: pages });
const ghostFetchPagesError = () => ({ type: GHOST_FETCH_PAGES_ERROR, error: true });
const selectPageBySlugSuccess = page => ({ type: SELECT_PAGE_BY_SLUG_SUCCESS, payload: page });
const selectPageBySlugError = () => ({ type: SELECT_PAGE_BY_SLUG_ERROR, error: true });

// ================ Thunks ================ //

export const fetchGhostPages = () => (dispatch, getState, sdk) => {
  if (getState().ghost.ghostPages.length != 0) {
    return Promise.resolve();
  }

  const action =
    getState().ghost.ghostConnection === false ||
    getState().ghost.ghostPages.length === 0 ||
    !getState().ghost.ghostConnection.pages
      ? Promise.all([dispatch(ghostConnectToSdk(makeGhostConnection()))])
      : Promise.resolve();

  return action
    .then(() => {
      return getState().ghost.ghostConnection.pages.browse({
        include: ['tags'],
        fields: ['title', 'slug'],
      });
    })
    .then(pages => {
      dispatch(ghostFetchPagesSuccess(pages));
      return pages;
    })
    .catch(e => {
      dispatch(ghostFetchPagesError());

      log.error(e, 'ghost-menu-fetch-failed');
      //#272 the SSL is broken for ghost and for some reason we can't fix it
      //the issue with the code is that this throwing of an exception is breaking the site as nothing is catching it
      //so execution is stopping...
      //we're hacking the site to see if just removing this exception will allow the site to work but have no ghost content
     // throw e;
    });
};

export const selectPageBySlug = slug => (dispatch, getState, sdk) => {
  const action =
    getState().ghost.ghostConnection === false
      ? Promise.all([dispatch(ghostConnectToSdk(makeGhostConnection()))])
      : Promise.resolve();

  return action
    .then(() => {
      return getState().ghost.ghostConnection.pages.read({ slug: slug });
    })
    .then(page => {
      return dispatch(selectPageBySlugSuccess(page));
    })
    .catch(e => {
      dispatch(selectPageBySlugError());

      log.error(e, 'ghost-page-by-slug-fetch-failed');
      throw e;
    });
};

export const menuFirstFooterColumn = pages => filterPages(pages, 'first-footer-column');
export const menuSecondFooterColumn = pages => filterPages(pages, 'second-footer-column');
export const menuThirdFooterColumn = pages => filterPages(pages, 'third-footer-column');

const filterPages = (pages, tagName) =>
  pages.filter(p => p.tags.filter(t => t.name === tagName).length > 0);

const makeGhostConnection = () => {
  return new GhostContentAPI({
    url: config.ghost.url,
    key: config.ghost.key,
    version: 'v3',
  });
};

export const loadData = (params, search) => dispatch => {
  const { ghostSlug } = params;

  return dispatch(selectPageBySlug(ghostSlug));
};
